import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts';
import Loader from './components/App-Loader.js';
import HomePage from './screens/homepages.js';

const LoginPage = React.lazy(() => import('./screens/login'));
const DashboardPage = React.lazy(() => import('./screens/dashboard'));
const DocDashboard = React.lazy(() => import('./screens/doctordash'));
const WardDashboard = React.lazy(() => import('./screens/warddashboard'));

function AppRouter() {
    const { isAuthenticated, login } = useAuth();
 
    useEffect(() => {
        if (['Facility', 'UserId', 'BaseUrl', 'Token'].every(key => localStorage.getItem(key))) {
            login();
        }
    }, [login])

    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage />} />
                    

                    {/* Private Routes */}
                    <Route
                        path="/dashboard"
                        element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="/doctordash"
                        element={isAuthenticated ? <DocDashboard /> : <Navigate to="/login" />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/login" />}
                    />
                     <Route
                        path="/warddashboard"
                        element={isAuthenticated ? <WardDashboard /> : <Navigate to="/login" />}
                    />
                    
                    {/* <Route
                        path="*"
                        element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />}
                    /> */}
                        <Route path="/homepages" element={<HomePage />} />
                </Routes>
            </Suspense>

        </Router>
    );
}

export default AppRouter;
