import React, { useState } from 'react';
import { Select, Button } from "antd";
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function HomePage() {
    const [selectedPage, setSelectedPage] = useState(null);
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (selectedPage) {
            navigate(`/${selectedPage}`);
        } else {
            alert("Please select a page to navigate.");
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
             <div className='card'>
            <div className='header-for-dashboard2'>
            <img src={'drhmslogo.png'} alt={'QMS Logo'} />
            </div>
            <h2>Choose a Page</h2>
            <Select
                placeholder="Select a page"
                style={{ width: 200, marginBottom: '20px' }}
                onChange={(value) => setSelectedPage(value)}
            >
                <Option value="dashboard">Dashboard</Option>
                <Option value="warddashboard">Ward Dashboard</Option>
                <Option value="doctordash">Doctor Dashboard</Option>
                
            </Select>
            <br />
          
            <Button onClick={handleNavigate} 
              style={{
                backgroundColor: '#73e08a',
                borderColor: '#73e08a',
                color: 'white',
                boxShadow: 'none',
                transition: 'none',
                
                
              }}>
                Go to Selected Page
              </Button>
              </div>
        </div>
    );
}

export default HomePage;
